import React, { useEffect } from 'react'
import AboutUsComponent from '../components/pageComponents/aboutUsComponent'
import Wrapper from '../components/wrapper'

const ResponsiveAsyncGraphic = ({ location }) => {
  useEffect(() => {
    ;(function (e, i, n, s) {
      var t = 'InfogramEmbeds',
        d = e.getElementsByTagName('script')[0]
      if (window[t] && window[t].initialized)
        window[t].process && window[t].process()
      else if (!e.getElementById(n)) {
        var o = e.createElement('script')
        o.async = 1
        o.id = n
        o.src = 'https://e.infogram.com/js/dist/embed-loader-min.js'
        d.parentNode.insertBefore(o, d)
      }
    })(document, 0, 'infogram-async')
  }, [])
  return (
    <Wrapper location={location} title="Responsive Async Graphic | MedReps.com">
      <div>
        <div
          class="infogram-embed"
          data-id="4bbee25f-036b-4785-90b0-a18f9eacc1bb"
          data-type="interactive"
          data-title="Report Classic"
        ></div>
        <div
          style={{
            padding: '8px 0',
            fontFamily: 'Arial !important',
            fontSize: '13px !important',
            lineHeight: '15px !important',
            textAlign: 'center',
            borderTop: '1px solid #dadada',
            margin: '0 30px',
          }}
        >
          <a
            href="https://infogram.com/4bbee25f-036b-4785-90b0-a18f9eacc1bb"
            style={{
              color: '#989898 !important',
              textDecoration: 'none !important',
            }}
            target="_blank"
          >
            Report Classic
          </a>
          <br />
          <a
            href="https://infogram.com"
            style={{
              color: '#989898 !important',
              textDecoration: 'none !important',
            }}
            target="_blank"
            rel="nofollow"
          >
            Infogram
          </a>
        </div>
      </div>
    </Wrapper>
  )
}
export default ResponsiveAsyncGraphic
